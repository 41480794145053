footer{
    width: 100%;
    max-height: 300px;
    background:#2196f3 !important;
    position:absolute;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
}
.bottom-row{
    padding-top: 1rem !important;
    font-size: .9rem;
    color: rgba(255,255,255,0.6);
    background-color: rgba(0,0,0,0.2);
}