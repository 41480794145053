.card-columns {
    column-count: 6;
}
.card {
    height: 270px;
    width: 400px;
}

.card-body{
    padding: 0.5rem;    
}
.card-text{
    /* margin-bottom: 10px;
    line-height: 20px;
    max-height: 90%;
    position: absolute;  */
    margin-bottom: 10px;
    line-height: 20px;
    position: absolute; 
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
}
@media screen and (max-width: 1000px) {   
    .card-columns {
        column-count: 3;
    }
    .card {
        flex:33%;
        width: fit-content !important;
    }
  }
/* mobile screen  */
@media screen and (max-width: 600px) {
    .card-text {
        text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
        font-weight: bold;
    }       
    .card-columns {
        column-count: 2;
    }
  }