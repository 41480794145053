.shortcut{
    border-radius: 25px;
  border: 2px solid black;
  padding: 20px;
  width: 80%;
  height: 90%;
  box-shadow: 3px 6px #888888;
}

.button {
    padding: 15px 20px;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    outline: none;
    color: #fff;
    background-color: #fff;
    border-radius: 25px;
    border: 2px solid black;
    box-shadow: 3px 6px #888888;
    width: 80%;
    height: 90%;
  }
  
  .button:hover {background-color: #cecece}
  
  .button:active {
    background-color: #7fbdf7;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .button-text {
    font-size: 2rem;
  }
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .button {
      width: 100%;
      height: 6rem;
      margin-bottom: 10px;
    }
    .button-text {
      font-size: 1.2rem;
    }
  }