
.thumbsContainer {
    display: flex; 
    flex-direction: row; 
    flex-wrap: wrap; 
    margin-top: 16px;
}

.thumb {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 130px;
    height: 130px;
    padding: 4px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
}

.thumbInner {
    display: flex;
    min-width: 0;
    overflow: hidden;
    position: relative;
}

.thumb-delete{
    position: absolute;
    top: -10px;
    right: 0px;
    z-index: 2;
    color: red;
    font-size: 30px;
    font-weight: bold;
}

.img {
    display: block;
    width: 100%;
}

/*.delete {
    color: red;
    opacity: 0;
    transition: opacity 2s linear;
    -moz-transition: opacity 2s linear;
    -webkit-transition: opacity 2s linear;
}

.thumb:hover .delete {
    opacity: 1;
}*/

.rich-text-editor {
    max-width: 42em;
    background: rgb(255, 255, 255);
    padding: 20px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.thumb-radio {
    position:absolute;                  
    bottom:0;                          
    left:0;
    width:2em;
    height:2em;
}