header{
  position: sticky;
  top: 0;
  z-index: 16;
}

.nav-bar {
    width: 100%;
    /* position:-webkit-sticky; position:sticky; top:0; */
    position: relative;
    display:block;
    z-index: 2;    
}

.navbar-light .navbar-nav .nav-link {
    color: white;
}

.navbar-button {
    
    width: 50px;
    display: block;
   margin-right: 10px;
    /* border: 1px solid #00bfb6; */
    padding: 2px 10px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
}
.navbar-button div {
    height: 3px !important;
  background: #fff;
  margin: 5px 0px 5px 0px;
  border-radius: 25px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  }
  .one {
      width: 15px;
  }
  .two {
    width: 20px;
  }
  
  .three {
    width: 25px;
  }
  
  .navbar-button:hover div {
    width: 26px;
  }

  .navbar-button:hover {
    /* border: 3px solid white; */
    cursor: pointer;
  }