.main-container {
  padding-bottom: 180px;
}
  #overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 17;
    cursor: pointer;
  }
/* mobile screen  */
@media screen and (max-width: 600px) {
  .main-container{
    padding-bottom: 210px;/*because on small screen like iphone 8 plus footer text will be break the line*/
  }
}