
  .sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    top: 0;
    left: 0;
    background-image: linear-gradient(180deg, rgba(5, 39, 103, 0.95) 0%, black 70%);;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }
  
/* Style the sidenav links and the dropdown button */
.sidenav a, .dropdown-btn {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 18px;
  color: #aaaaaa;
  display: block;
  transition: 0.3s;

  /* new */
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
  border-radius: 4px;
}

  
  /* On mouse-over */
.sidenav a:hover, .dropdown-btn:hover {
  background-color: rgba(255,255,255,0.1);
  color: white;
}
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    text-align: right;
    /* right: 25px; */
    font-size: 36px;
    /* margin-left: 50px; */
  }
  
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }

  /* Add an active class to the active dropdown button */
.sidenav .active {
  background-color: rgba(255,255,255,0.25);
  color: white;
}

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-container {
  /* display: none; */
  /* background-color: #262626; */
  background-color: none;
  padding-left: 30px;
  font-size: 16px;
  font-style: oblique;
}

/* Optional: Style the caret down icon */
.fa-caret-down {
  float: right;
  padding-right: 8px;
}
.sidenav i {
  padding-right: 0.5rem;
}